<template>
    <div class="cloud-cost-stat-view">
        <div class="ctrl-bar">
            <div class="label">云商：</div>
            <n-select
                v-model:value="cloud"
                :options="cloudOpts"
                size="small"
                class="input"
                style="width: 100px"
                @update:value="onQueryChange"></n-select>
            <div class="label">年度：</div>
            <n-select
                v-model:value="period"
                :options="periodOpts"
                size="small"
                class="input"
                style="width: 300px"
                @update:value="onQueryChange"></n-select>
        </div>
        <div v-if="originData" class="content">
            <n-spin :show="loading">
                <div class="blocks">
                    <div class="item">
                        <div class="key">年度低消额度</div>
                        <div class="value">
                            ${{
                                originData.cloud_target &&
                                formatNumberWithCommas(
                                    originData.cloud_target.toFixed(2)
                                )
                            }}
                        </div>
                    </div>
                    <div class="item">
                        <div class="key">本年度完成额</div>
                        <div class="value">
                            ${{
                                originData.sum_cloud_cost &&
                                formatNumberWithCommas(
                                    originData.sum_cloud_cost.toFixed(2)
                                )
                            }}
                        </div>
                    </div>
                    <div class="item">
                        <div class="key">本年度完成率</div>
                        <div class="value">
                            {{
                                originData.sum_cloud_rate &&
                                (originData.sum_cloud_rate * 100).toFixed(2)
                            }}%
                        </div>
                    </div>
                </div>
            </n-spin>
            <n-data-table
                style="background-color: #fff"
                :loading="loading"
                :columns="tableColumns"
                :data="detailList"></n-data-table>
        </div>
    </div>
</template>

<style lang="less" scoped>
@import '../../../common/common.less';
.cloud-cost-stat-view {
    .common-content;

    .ctrl-bar {
        .common-ctrl-bar;
    }

    .content {
        margin-top: 10px;
        padding-bottom: 50px;
        .blocks {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            min-height: 50px;

            .item {
                .standard-border;
                .standard-shadow;
                width: 30%;
                margin-right: 10px;
                margin-bottom: 10px;
                background-color: #fff;
                padding: 10px 10px 6px;
                flex-grow: 1;

                &:last-child {
                    margin-right: 0;
                }

                .key {
                    font-weight: bold;
                    font-size: 14px;
                }

                .value {
                    font-size: 20px;
                    line-height: 30px;
                    color: @primary-color;
                }
            }
        }
    }
}
</style>

<script setup>
import { ref, computed, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { NSelect, NDataTable, NSpin, useMessage } from 'naive-ui';
import dayjs from 'dayjs';
import { BusinessAnalysisAPI } from '@/common/API';
import { formatNumberWithCommas } from '@/common/tools';

const route = useRoute();
const router = useRouter();
const message = useMessage();

let mapOrigin = ref(null);

function loadPeriods() {
    BusinessAnalysisAPI.getCloudCostPeriodList().then(res => {
        if (res.error_no !== 0) {
            return;
        }
        mapOrigin.value = res.data;
    });
}

loadPeriods();

let cloudOpts = computed(() => {
    if (!mapOrigin.value) {
        return [];
    }
    return Object.keys(mapOrigin.value).map(key => ({
        label: key,
        value: key,
    }));
});

let cloud = ref('aws');

let periodOpts = computed(() => {
    if (!mapOrigin.value) {
        return [];
    }
    let list = mapOrigin.value[cloud.value] || [];
    return list.map(item => ({
        label: `${item.period}(${item.start_date} ~ ${item.end_date})`,
        value: item.period,
    }));
});

let period = ref('Y1');

let loading = ref(false);

let tableColumns = ref([
    { title: '年份', key: 'year', width: 80, align: 'center' },
    { title: '月份', key: 'month', width: 80, align: 'center' },
    {
        title: '服务器计费',
        key: 'cloud_cost',
        align: 'center',
        render(row) {
            return row.cloud_cost === null
                ? '-'
                : '$' + formatNumberWithCommas(row.cloud_cost.toFixed(2));
        },
    },
    {
        title: '优惠',
        key: 'cloud_credit',
        align: 'center',
        render(row) {
            return row.cloud_credit === null
                ? '-'
                : '$' +
                      formatNumberWithCommas(
                          Math.abs(row.cloud_credit).toFixed(2)
                      );
        },
    },
    {
        title: '实付',
        key: 'real_cost',
        align: 'center',
        render(row) {
            return row.real_cost === null
                ? '-'
                : '$' + formatNumberWithCommas(row.real_cost.toFixed(2));
        },
    },
    {
        title: '人工credit',
        key: 'm_credit',
        align: 'center',
        render(row) {
            return row.m_credit === null
                ? '-'
                : '$' + formatNumberWithCommas(row.m_credit.toFixed(2));
        },
    },

    {
        title: '协议完成额',
        align: 'center',
        render(row) {
            return row.cloud_complete_cost === null
                ? '-'
                : '$' +
                      formatNumberWithCommas(
                          row.cloud_complete_cost.toFixed(2)
                      );
        },
    },
    {
        title: '完成率',
        key: 'cloud_rate',
        width: 100,
        align: 'center',
        render(row) {
            return row.cloud_rate === null
                ? '-'
                : (row.cloud_rate * 100).toFixed(2) + '%';
        },
    },
]);

function genEmptyDetails(startDate, count) {
    let sDate = dayjs(startDate);
    let list = [];
    for (let i = 0; i < count; i++) {
        if (count !== 12 || i !== 0) {
            sDate = sDate.add(1, 'month');
        }
        list.push({
            year: sDate.year(),
            month: sDate.month() + 1,
            cloud_cost: null,
            cloud_credit: null,
            real_cost: null,
            cloud_rate: null,
            cloud_complete_cost: null,
            m_credit: null,
        });
    }
    return list;
}

let originData = ref(null);

let detailList = computed(() => {
    if (!originData.value) {
        return [];
    }
    let originDetails = originData.value.detail || [];
    if (originDetails.length === 0) {
        function getPeriodStartDate() {
            if (!mapOrigin.value) {
                return '2023-10-01';
            }
            return mapOrigin.value[cloud.value].find(
                item => item.period === period.value
            ).start_date;
        }
        return genEmptyDetails(getPeriodStartDate(), 12);
    }
    let last = originDetails[originDetails.length - 1];
    return [
        ...originDetails,
        ...genEmptyDetails(
            `${last.year}-${last.month}-01`,
            12 - originDetails.length
        ),
    ];
});

function loadData() {
    loading.value = true;
    BusinessAnalysisAPI.getCloudCostStat(cloud.value, period.value)
        .then(res => {
            loading.value = false;
            if (res.error_no !== 0) {
                message.error(res.error_msg || '未知异常导致加载失败');
                return;
            }
            originData.value = res.data || {};
        })
        .catch(err => {
            message.error('未知异常导致加载失败');
            console.log(err);
            loading.value = false;
        });
}

function onQueryChange() {
    router.replace({ query: { cloud: cloud.value, period: period.value } });
    loadData();
}

onMounted(() => {
    if (route.query.cloud) {
        cloud.value = route.query.cloud;
    }
    if (route.query.period) {
        period.value = route.query.period;
    }
    loadData();
});
</script>
